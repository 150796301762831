.properties-all {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .property {
        width: 33%;
        padding-bottom: calc(33% * 1.36);
        position: relative;        
        overflow: hidden;
        margin: 0.1665%;
        &:nth-child(1),&:nth-child(3n+4) {
            margin-left: 0;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
        a.property-inner {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: white;
            text-decoration: none;
            transition: all 1s ease;
            &::after {
                content: '';
                position: absolute;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
                height: 30%;
                width: 100%;
                top: left;
                bottom:0;
                transition: all 0.5s ease;
                
            }
            &:hover {
                transition: max-height 1s ease;
                &::after {
                    height: 100%;
                    
                }
            }
            .info {
                margin: auto 1.2rem 1.2rem 1.2rem;
                color: inherit;
                overflow: hidden;
                transition: max-height 1s ease-out;
                -webkit-transition: max-height 1s ease-out;
                z-index: 10;
                position: absolute;
                bottom: 0;
                h2 {
                    font-size: 1.5em;
                    font-weight: 400;
                    margin-bottom: 0.5rem;
                    color: inherit;
                    text-decoration: underline;
                }                    
                p {
                    font-size: 0.85em;
                    margin: 0;
                    color: inherit;
                    text-decoration: none;                        
                }
            }
        }
    }
    @media (max-width: $mobileBreakpoint) {
        display: block;
        .property {
            width: 100%;
            margin: 0 0 1% 0;
            padding-bottom: calc(100% * 1.14);
        }
    }   
}