.article {
    img {
        width: 100%;
        height: auto;
    }
    .article-content {
        width: 700px;
        .intro, .content {
            font-size: $contentFontSize;
            line-height: $contentLineHeight;
        }
        .intro {
            font-weight: 700;
        }
    }
    @media (max-width: $mobileBreakpoint) {
        .article-content {
            width: 100%;
        }
    }
}