.links {
    margin-top: 5em;
    a {
        display: block;
        font-size: $contentFontSize;
        line-height: $contentLineHeight;
        margin-bottom: 0.5rem;
    }
    .box {
        background: $backgroundBlue;
        padding: 3rem;
        margin-bottom: 0.5rem;
        h2 {
            font-weight: 300;
            font-size: 3.125rem;
            margin-top: 0;
        }
        p {
            font-size: 1.5rem;
            max-width: 45ch;
            line-height: 1.5;
        }
        .employee {
            display: flex;
            margin-top: 2rem;
            .img {
                width: 150px;
                height: 150px;
                background-position: center top;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 90px;
                border: 5px solid white;
            }
            .contact {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 2rem;
                h3 {
                    font-size: 1.5rem;
                    font-weight: 400;
                    margin: 0 0 0.5em 0;
                }
                h4 {
                    font-size: 0.875rem;
                    margin: 0 0 0.5em 0;
                }
                a {
                    color: $color;
                    font-size: 1rem;
                }
            }
        }
    }
}
@media (max-width: $mobileBreakpoint) {
    .links {
        .box {
            padding: 1.5rem;
            h2 {
                font-size: 2.125rem;
            }
            p {
                font-size: 1rem;

            }
            .employee {
                display: block;
                .contact {
                    margin: 1rem 0 0 0;
                }
            }            
        }
    }
}