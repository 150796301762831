/*
    Theme Name: Holm eiendom
    Author: HyperRedink AS
*/

//Vars
$color: #373737;
$colorLighter: #454545;
$linkColor: $color;
$backgroundBlue: rgba(11,123,255,0.1);
$grey: #848484;

$contentFontSize: 1.5em;
$contentLineHeight: 1.4;

$mobileBreakpoint: 850px;
$innerPadding: 5rem;
$innerPaddingMob: 2rem;

* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}
body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: $color;
    h1 {
        font-size: 3.125em;
        font-weight: 300;
        margin-bottom: 1.5em;
    }
    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    h3 {
        font-size: 1em;
        margin-bottom: 1rem;
    }
    a {
        color: $linkColor;
    }
    .content {
        p {
            margin-top: 1rem;
            margin-bottom: 2.5rem;
        }
    }
    .main-wrapper {
        width: 100%;
        max-width: 1400px;
        margin: auto;
        padding: 2rem;
    }
    .inner {
        padding-left: $innerPadding;
        padding-right: $innerPadding;
    }
    @media (max-width: $mobileBreakpoint) {
        font-size: 10.666666666666667px;
        .main-wrapper {
            padding: 0;
        }
        .inner {
            padding-left: $innerPaddingMob;
            padding-right: $innerPaddingMob;
        }
    }
}

@import '_head.scss';
@import '_front.scss';
@import '_availableProperties.scss';
@import '_propertiesAll';
@import '_property.scss';
@import '_content.scss';
@import '_contact.scss';
@import '_links.scss';
@import '_footer.scss';
