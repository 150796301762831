body.home {
    h2 {
        color: $colorLighter;
        font-weight: 700;
        font-size: 1rem;
        margin: 0;
    }
    .intro {
        width: 100%;
        padding-top: calc(100% * 0.52);
        position: relative;
        .title {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1 {
                color: white;
                font-weight: 300;
                margin-left: $innerPadding;
                max-width:  22ch;
                font-size: 3.125em;

            }
        }
    }
    .content-flex {
        display: flex;
        padding-top: 5em;
        padding-bottom: 5em;
        font-size: $contentFontSize;
        line-height: $contentLineHeight;
        .content {
            width: 60%;
            p {
                max-width: 75%;
                margin: 0;
            }
            a {
                margin-top: 2em;
                display: block;
            }
        }
        .posts {
            width: 40%;
            .link {
                a {
                    display: block;
                    color: $color;
                    margin: 1.5em 0;      
                    max-width: 30ch;         
                }
                &::after {
                    content: '';
                    display: block;
                    width: 50px;
                    height: 2px;
                    background: $linkColor;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
    .property-wrapper {
        padding-top: 1.5em;
    }
    @media (max-width: $mobileBreakpoint) {
        .intro {
            padding-top: calc(100% * 0.6);
            .title {
                h1 {
                    margin-left: $innerPaddingMob;
                    font-size: 2.45em;
                    max-width: 12ch;
                }
            }
        }
        .content-flex {
            display: block;
            line-height: 1.4;
            .content {
                width: 100%;
                p {
                    max-width: none;
                }
            }
            .posts {
                width: 100%;
                margin-top: 2em;
            }
        }
    }
}