footer {
    padding-top: 5rem;
    .wrapper {
        padding-top: 2.5rem;
        border-top: solid 1px $color;
        width: 100%;
        .address, .contact, .some {
            margin-bottom: 2.5rem;
        }
        .address, .contact {
            text-align: center;
            h4 {
               font-size: 1rem;
               margin: 0.5rem 0;
               font-weight: 700;
            }
            p {
                font-size: 0.8125rem;
                margin: 0.5rem 0;
            }
            a {
                color: $color;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .some {
            width: 100%;
            display: flex;
            justify-content: center;
            div {
                height: 50px;
                width: 50px;
                margin: 0 0.5em;
                a {
                    font-size: 0;
                    display: block;
                    width: 100%;
                    height: 100%;                    
                    background: url('../../img/icon-facebook.svg');
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                    &:hover {
                        svg {
                        path:first-child {
                            fill: black;
                        } 
                        }
                    }
                }
            }
        }
    }
}