body.single-properties {
    .swiper-container {
        width: 100%;
        padding-bottom: calc(100%*0.56);
        position: relative;
        .swiper-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
        .swiper-slide {
            object-fit: cover;
        }
        .swiper-button-prev,
        .swiper-button-next {
            height: 3rem;
            width: 3rem;
            background: white;
            border-radius: 90px;
            opacity: 0.85;
            margin-top: 0;
            top: calc(50% - 1.5rem);
            &::after {
                color: $color;
                font-size: 1.5em;
            }
        }
        .swiper-pagination-bullet-active {
            background-color: white;
        }
    }
    h1 {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }
    .info {
        display: flex;
        & > div {
            margin-right: 6em;
        }
        h2 {
            color: $colorLighter;
            font-weight: 700;
            font-size: 0.875rem;
            margin: 0 0 1em 0;
        }
        p {
            color: $grey;
            margin: 0;
            a {
                color: inherit;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

    }
    .content {
        margin-top: 3em;
        width: 100%;
        max-width: 700px;
        font-size: $contentFontSize;
        line-height: $contentLineHeight;
    }
    .map {
        margin-top: 4em;
        width: 100%;
        padding-bottom: calc(100% * 0.6);
        position: relative;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

}
@media (max-width: $mobileBreakpoint) {
    body.single-properties {
        .swiper-container {
            width: calc(100% + (#{$innerPaddingMob} * 2));
            padding-bottom: calc((100% + (#{$innerPaddingMob} * 2)) * 0.56);
            margin-left: -$innerPaddingMob;
            .swiper-button-prev,
            .swiper-button-next {
                height: 1.5rem;
                width: 1.5rem;
                background: white;
                border-radius: 90px;
                opacity: 0.85;
                top: calc(50% - 0.75rem);
                &::after {
                    color: $color;
                    font-size: 0.75em;
                }
            }
        }
        
        .info {
            display: block;
            h2 {
                font-size: 0.625rem;
            }
            &> div {
                margin-bottom: 2em;
            }
        }
    }
}