.contact-info {
    font-size: 1.5em;
    a {
        color: inherit;
    }
    margin-bottom: 5em;
}
.employees {
    h2 {
        font-weight: 300;
        font-size: 2.375rem;
    }
    .wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .employee {
            width: 33%;
            margin: 0.1665%;
            margin-bottom: 4rem;
            &:nth-child(1),&:nth-child(3n+4) {
                margin-left: 0;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
            .img {
                width: 100%;
                padding-bottom: calc(100% * 1.14);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
            a {
                display: block;
                color: $colorLighter;
                margin-bottom: 0.25rem;
            }
            h3 {
                color: $colorLighter;
                font-size: 1.5rem;
                font-weight: 400;
                margin-bottom: 0.5rem;
            }
            h4 {
                color: $colorLighter;
                font-weight: 700;
                font-size: 0.875rem;
                margin: 0;
                text-transform: uppercase;
                margin-bottom: 0.5rem;
            }
        }
    }
    @media(max-width: $mobileBreakpoint) {
        .wrapper {
            display: block;
            .employee {
                width: 100%;
                margin: 0 0 4rem 0;
                .img {
                    padding-bottom: calc(100% * 0.9);
                }
            }
        }
    }
}   