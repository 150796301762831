.property-wrapper {
    h2 {
        margin-bottom: 2em;
    }
    .properties {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .property {
            width: 33%;
            margin: 0.1665%;
            margin-bottom: 3em;
            &:nth-child(1),&:nth-child(3n+4) {
                margin-left: 0;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
            a {
                color: $color;
                h3 {
                    font-weight: 400;
                    font-size: 1.5em;
                    line-height: 1.4;
                    max-width: 20ch;
                    margin-top: 0.6em;
                }

            }
            .image {
                width: 100%;
                padding-bottom: calc(100% * 1.14);
                position: relative;
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
            .address {
                font-weight: 700;
                font-size: 0.875em;
                line-height: auto;
                margin-bottom: 1em;
                text-transform: uppercase;
            }
            .info {
                font-size: 1em;
                line-height: auto;
                color: $grey;

            }
            &:hover {
                img {
                    opacity: 0.7;
                }
                a {
                    color: black;
                }

            }
        }
    }
    @media (max-width: $mobileBreakpoint) {    
        .properties {
            display: block;
            .property {
                width: 100%;
                margin: 0 0 3rem 0;
                a {
                    h3 {
                        max-width: none;
                    }
                }
            }
        }
    }
}