header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1rem;
    .logo {
        font-size: 0;
        width: 60px;
        padding-top: calc(60px * 1.28);
        position: relative;
        a {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            img {
                width: 100%;
                height: auto;
                padding: 1px;
            }
        }
    }
    .hamburger-wrapper {
        display: flex;
        .toggler, .hamburger {
            display: none;
        }
        .menu-wrap {
            display: flex;
            .menu-main-nav-container {
                display: flex;
                .menu {
                    margin: 0;
                    display: flex;
                    margin: auto;
                    li {
                        list-style: none;
                        margin-left: 2em;
                        display: flex;
                        flex-direction: column;
                        a {
                            color: $color;
                            font-size: 1.125rem;
                            text-decoration: none;
                            margin: auto;
                            &::after {
                                content: "";
                                width: 0;
                                height: 1px;
                                display: block;
                                margin-top: 1px;
                                background: transparent;
                                transition: width 0.4s ease, background-color 0.4s ease;
                            }
                            &:hover {
                                &::after {
                                    width: 100%;
                                    background: $color;
                                }
                            }
                        }
                        &.current-menu-item {
                            a {                         
                                color: $linkColor;
                                text-decoration: none;
                                &::after {
                                    width: 100%;
                                    background: $color;
                                }
                            }
                        }
                    }
                } 
            }
        }
    }
    @media (max-width: $mobileBreakpoint) {
        padding: calc(#{$innerPaddingMob} / 2);
        .logo {
            width: 30px;
            padding-top: calc(30px * 1.28);
        }
        .hamburger-wrapper {
            position: relative;
            height: 30px;
            width: 30px;
            .toggler {
                display: block;
                opacity: 0;
                z-index: 10;
                position: absolute;
                right: 0;
                height: 30px;
                width: 30px;
                margin:0;
                &:checked + .hamburger > div {
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                    -moz-transform: rotate(135deg);
                    -ms-transform: rotate(135deg);
                    -o-transform: rotate(135deg);
                    &::before,
                    &::after {
                        top:0;
                        transform: rotate(90deg);
                        -webkit-transform: rotate(90deg);
                        -moz-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        -o-transform: rotate(90deg);
                    }
                }
                &:checked ~ .menu-wrap {
                    visibility: visible;
                }
                &:checked ~ .menu-wrap .menu-main-nav-container {
                    opacity: 1;
                }
                &:not(:checked) ~ .menu-wrap {
                    visibility: hidden;
                }
            }
            .hamburger {
                display: flex;
                align-content: center;
                align-items: center;
                align-self: center;
                position: relative;
                right: 0;
                width: 28px;
                height: auto;
                z-index: 5;
                > div {
                    position: relative;
                    width: 100%;
                    height: 2px;
                    background: $color;
                    display: flex;
                    align-content: center;
                    align-items: center;
                    -webkit-transition: all .4s ease;
                    -moz-transition: all .4s ease;
                    -ms-transition: all .4s ease;
                    -o-transition: all .4s ease;
                    transition: all .4s ease;
                    &::before,&::after {
                        content: '';
                        position: absolute;
                        z-index: 1;
                        top: -10px;
                        width: 100%;
                        height: 2px;
                        background: $color;
                    }
                    &::after {
                        top: 10px;
                    }

                }
            }
            .menu-wrap {
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                visibility: hidden;
                overflow: hidden;
                display: flex;
                flex: none;
                align-items: center;
                justify-content: center;
                z-index: 3;
                .menu-main-nav-container {
                    opacity: 0;           
                    transition: all 0.4s ease;
                    background: transparent;
                    height: 100%;
                    width: 100%;
                    flex-direction: column;
                    background: rgba(255,255,255,0.95);
                    ul.menu {
                        display: block;
                        padding: 0;
                        margin-left: $innerPaddingMob;
                        li {
                            margin: 1.5em 0;
                            display: block;
                            width: auto;
                            &.current-menu-item {
                                a {                         
                                    color: $color;
                                    text-decoration: none;
                                }
                            }
                            a {
                                font-size: 2.5em;
                                color: $linkColor;
                                text-decoration: underline;
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                }
            }
        }


    }
}

.ie9 img[src$=".svg"] {
    width: 100%; 
  }
  /* 2 */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    img[src$=".svg"] {
      width: 100%; 
    }
  }